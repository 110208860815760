<template>
    <footer>
        <div class="container">
            <div class="left-elements">
                <img src="../assets/fw_logo.png" style="width:75px;height:75px;">
                <div>
                    <v-btn href="https://twitter.com/FurryWeekly" dark icon>
                        <v-icon>mdi-twitter</v-icon>
                    </v-btn>
                    <v-btn href="https://www.instagram.com/furryweekly/" dark icon>
                        <v-icon>mdi-instagram</v-icon>
                    </v-btn>   
                </div>
            </div>
            <div class="middle-elements">
                <router-link class="link" :to="{ name: 'HomePage' }">HOME</router-link>
                <router-link class="link" :to="{ name: 'Archives' }">ARCHIVES</router-link>
                <router-link class="link" :to="{ name: 'Contacts' }">CONTACT</router-link>
                <router-link class="link" :to="{ name: 'Submissions' }">SUBMIT</router-link>
            </div>
            <div class="right-elements">
                <p>Copyright 2023 All Rights Reserved</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "footer-vue"
}
</script>

<style lang="scss" scoped>
footer {
    margin-top: auto;
    padding: 30px 25px;
    background-color: #000;
    z-index: 99;

    .container {
        color: #ffff;
        display: flex;
        flex-direction: column;
        @media (min-width: 800px) {
            flex-direction: row;
        }
    }

    .left-elements {
        display: flex;
        flex: 1;
        flex-direction: column;
        text-align: center;
        align-items: center;
        @media (min-width: 800px) {
            flex-direction: row;
            text-align: initial;
            margin-top: auto;
        }
    }

    .middle-elements {
        display: flex;
        flex: 1;
        margin: 10px;
        height: 100%;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        @media (min-width: 800px) {
            flex-direction: column;
        }

        .link {
            font-weight: 500;
            padding: 0 8px;
            transition: 0.3s color ease;
            color: white;
            text-decoration: none;
        }
    }

    .right-elements {
        display: flex;
        flex: 1;
        align-items: center;
        flex-direction: column;
        margin-top: auto;
        @media (min-width: 800px) {
            align-items: flex-end;
        }
    }

}

</style>