<template>
    <div class="contacts-wrapper">
        <div class="contacts-content">
            <div class="contacts-title">General Contact Information</div>
            <div>
                If you have any general comments, questions or concerns about Furry Weekly, you're welcome to reach 
                out to us via a direct message on Twitter/X or Instagram!
            </div>
            <div class="contacts-center">
                <v-btn href="https://twitter.com/FurryWeekly" x-large icon><v-icon>mdi-twitter</v-icon></v-btn>
                <v-btn href="https://www.instagram.com/furryweekly/" x-large icon><v-icon>mdi-instagram</v-icon></v-btn>
            </div>
            <div>
                You can also email us at: <i>furryweeklysocial@gmail.com</i>
            </div>
            <div>
                You're also welcome to reach out to <a href="https://linktr.ee/Yeshua_">Yeshua</a> or
                <a href="https://linktr.ee/Carkas">Carkas</a> for anything regarding Furry Weekly!
            </div>
            <div class="contacts-title">Segment Contacts</div>
            <div>
                Furry Weekly has many segments with different topics, so read on below to learn exactly what each
                segment is about!
                <br>
                Our mission is to showcase the work of people in the fandom, so you can submit your content for us to
                review and share each week! You'll also learn about what type of content you can send us, and who
                you can contact if you'd like to submit something.
            </div>
            <div class="contacts-subtitle">Art Corner</div>
            <div>
                Submit your visual art, whether it's traditional, digital, or in another medium! In this segment we'll
                show off your work and review its best features!
                <br><br>
                For any questions about Art Corner or to submit your work, contact
                <a href="https://linktr.ee/honeylemonsoap">Possum</a>
                or
                <a href="https://x.com/Martindawolf">Martin</a>
            </div>
            <div class="contacts-subtitle">Writing Showcase</div>
            <div>
                Submit your literature! Short stories, novels, poems, and anything else that's writing is welcome!
                We review your work and its best highlights, and offer light feedback.
                <br><br>
                For any questions about Writing Showcase or to submit your work, contact
                <a href="https://linktr.ee/furrytomes">Tomes</a>
            </div>
            <div class="contacts-subtitle">Fursuit Focus</div>
            <div>
                If you have a fursuit, let us show it off! No matter if it's a partial, full suit, realistic, toony,
                or anything else, show us your fursuit so we can share it along with your fursuiting experiences in
                the fandom!
                <br><br>
                For any questions about Fursuit Focus or to submit your fursuit, contact
                <a href="https://linktr.ee/tahlmorra">tahlmorra</a>
            </div>
            <div class="contacts-subtitle">Available Artists</div>
            <div>
                We want to help share work that you offer, including but not limited to visual art, crafts, fursuits,
                plushes, writing, and music. If you offer commissions, are taking on trades, looking for
                collaborations, or even just doing requests, let us get the word out! We'll include most important
                info like prices, what you offer, and anything else you'd like us to mention while linking back to you
                or any place where you have full details about the work you offer.
                <br><br>
                For any questions about Available Artists or to submit your content, contact
                <a href="https://lycanraptor.carrd.co/">Danielle</a>
            </div>
            <div class="contacts-subtitle">Radical Raffles</div>
            <div>
                Raffles are going on all the time in the fandom, so next time you host one, let us know and we'll
                showcase it along with other raffles! It is worth noting, we tend to avoid featuring raffles that have
                a 'gambling' aspect. Specifically, if someone has to either spend money or draw art to either enter or
                get additional entries to a raffle, we likely won't feature it.
                <br><br>
                For any questions about Radical Raffles or to submit your content, contact
                <a href="https://lycanraptor.carrd.co/">Danielle</a>
            </div>
            <div class="contacts-subtitle">Furry Quote</div>
            <div>
                In this segment, we present a quote that deals with an aspect of life, and just take a moment to see
                how it could be helpful! Our goal is to give a bit of motivation and offer uplifting messages. Of
                course, we may not be able to offer solutions if you're going through something, but we hope to
                brighten your day just a little by giving you a moment to reflect. If you have a particular quote
                you'd like to share with us, whether it's from you or someone else, just let us know!
                <br><br>
                For any questions about Furry Quote or to submit your quote, contact
                <a href="https://www.instagram.com/tide.the.wagon/">Tide</a>
            </div>
            <div class="contacts-subtitle">Character Interview</div>
            <div>
                There are tons of folks in the fandom that do some really cool stuff, and in this segment we sit down
                with them and just talk about what they do! This is of course pretty broad, but some examples of
                people we've featured include content creators, fandom apparel and merch creators, artists, writers,
                and much more! If there's someone you'd like us to interview, be sure to tell us!
                <br><br>
                For any questions about Character Interview or to make a suggestion, contact
                <a href="https://linktr.ee/Yeshua_">Yeshua</a>
            </div>
            <div class="contacts-subtitle">Convention Calendar</div>
            <div>
                This is your one stop for anything and everything about furry conventions and events happening
                throughout the year! This monthly segment brings you a look at each furry event we know of around the
                world for the *following* month. So for example, you can read about furry events in July at the end of
                June. We have all you need to know about these events, and if you have an event you'd like us to
                include, don't be afraid to reach out!
                <br><br>
                Here are some frequently asked questions about Con Calendar:
                <br><br>
                <i>
                    <b>Q:</b> I help run a furry convention that's coming up, and we'd like to get the word out. Is
                    that possible?
                </i>
                <br>
                <b>A:</b> Absolutely! Just <a href="http://shetani.carrd.co">contact our host Shetani</a> <i>before</i> the
                month in which the con takes place, and she'll make sure to have all the details needed to cover 
                your event!
                <br><br>
                <i>
                    <b>Q:</b> I'm not on staff, but I'm going to a furry con soon - will you write about it?
                </i>
                <br>
                <b>A:</b> Shetani tries her absolute best to give our readers comprehensive con coverage, but she doesn't
                know what she doesn't know! <a href="http://shetani.carrd.co">Get in touch with her</a> at least a
                month in advance so she can ascertain your con is on her list.
                <br><br>
                <i>
                    <b>Q:</b> Why do you list conventions for the whole month instead of each week?
                </i>
                <br>
                <b>A:</b> Most furry cons happen on Friday, Saturday, and Sunday, which, coincidentally, is when Furry Weekly
                releases new issues. If we waited to update Convention Calendar on a weekly basis, many of the cons
                would be partly finished or have already ended, which wouldn't give our readers enough time to go to
                them!
                <br><br>
                <i>
                    <b>Q:</b> Why leave up the cons from earlier in the month if they've already happened?
                </i>
                <br>
                <b>A:</b> Furry Weekly's fantastic graphic artists make a calendar image for the entire month. Those event
                names would still be on the calendar, except without an explanation of what they are. In addition,
                when people search online for furry conventions they can attend, Furry Weekly is frequently in the top
                results - particularly for cons that don't get a lot of mainstream coverage, or whose sites are
                primarily in another language. Cons without a large online footprint can be greatly assisted by Furry
                Weekly gathering all the important details in one place.
                <br><br>
                <i>
                    <b>Q:</b> Where do you find the conventions you write about?
                </i>
                <br>
                <b>A:</b> Mostly via word of mouth and a LOT of research! Many established furry cons are well-documented and
                happen at the same time each year. Shetani also scours the web to look for upcoming first year furry
                cons, furry cons outside of North America, and lesser known furry cons. These events often benefit the
                most from being featured in a publication like Furry Weekly.
                <br><br>
                <i>
                    <b>Q:</b> How do you pick which cons to feature?
                </i>
                <br>
                <b>A:</b> Furry events come in all shapes and sizes, and being exclusionary is no fun. Basically, if the
                event bills itself as a convention and is geared towards the furry community, that's exactly what
                we're looking for! There must be at least one official public webpage with verifiable info where
                prospective attendees can find details such as dates, location, how to register, policies, and
                programming.
                <br><br>
                For any questions about Con Calendar or to tell us about an event, contact
                <a href="http://shetani.carrd.co/">Shetani</a>
            </div>
            <div class="contacts-subtitle">Furry of the Week</div>
            <div>
                With so many kinds of people in the fandom, it's not uncommon to see different acts in and out the
                community. These acts can be helpful in some way and potentially even paint a positive image of the
                fandom to those outside of it. This is another segment where the range of what we feature can be
                broad, but some examples can include someone raising money for a charitable cause, sharing a good,
                informative PSA with the community, or a fursuiter making a child and their family happy while out in
                public! We just want to share instances of folks doing good things for each other! If you have a
                suggestion, we'd be happy to hear it!
                <br><br>
                For any questions about Furry of the Week or to give us your suggestion, contact
                <a href="https://lycanraptor.carrd.co/">Danielle</a>
            </div>
            <div class="contacts-subtitle">Animal of the Week</div>
            <div>
                If you want to learn more about real life animals, this segment is for you! Although not
                <i>entirely</i> based on the furry fandom, we do offer one adopt every segment based on the animal we
                feature that week. The money you pay for that adopt goes directly toward conservation efforts for that
                real life animal! So not only do you get a cool new character, but you also help a good cause!
                <br><br>
                For any questions about Animal of the Week or to suggest a species of animals you'd like us to focus
                on, contact <a href="https://linktr.ee/Zed_Shirogane">Zed</a>
            </div>
            <div class="contacts-subtitle">Art 101</div>
            <div>
                Art is arguably the biggest way folks in the fandom create, so if you're interested in learning about
                making art, this is the segment for you! We'll have tutorials, advice, and more on what you need to
                know, whether you're a new or experienced artist. You're also more than welcome to suggest specific
                topics you'd like for us to cover.
                <br><br>
                If you have questions about Art 101 or want to see a specific topic covered, contact
                <a href="https://decayedarts.carrd.co/">Shroom</a>
            </div>
            <div class="contacts-subtitle">Writing 101</div>
            <div>
                If you have a passion for writing stories or any sort of literature, be sure to check out this
                segment! We have plenty of tips, tricks and advice to help you with your writing! Whether you're
                interested in writing stories for your characters, want to try some poetry, or have other literature
                in mind, we're here to help! You can also suggest specific topics you'd like for us to cover.
                <br><br>
                For any questions about Writing 101 or to suggest a topic, contact
                <a href="https://www.discordapp.com/users/121049424108584960">Bryce</a>
            </div>
            <div class="contacts-subtitle">Photography 101</div>
            <div>
                Calling all photgrafurs! Just like the other 101 segments, no matter if you're a beginner or have been
                shooting for years, if you enjoy photography, we hope to help you learn a little bit as well as give
                you the chance to share your photos! We'll have lots of info on different techniques, equipment, and
                more. You can also suggest specific topics you'd like for us to cover.
                <br><br>
                For any questions about Photography 101 or to suggest a topic, contact
                <a href="https://linktr.ee/jaden711">Jaden</a>
                or
                <a href="https://www.instagram.com/kami.t.photography/">Sherry</a>
            </div>
            <div class="contacts-subtitle">Fursuit Design Tips</div>
            <div>
                If you're interested in fursuit making, you'll want to pay attention to this segment! We'll go over
                different info about different aspects of fursuit making, from preparing, to actually doing the craft!
                You can also suggest specific topics about fursuit making that you'd like for us to cover.
                <br><br>
                For any questions about Fursuit Designing Tips or to suggest a topic, contact
                <a href="https://www.instagram.com/olivierthespacegoat/">Olivier</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "contacts",
    data() {
        return {}
    }

}
</script>

<style lang="scss" scoped>
.contacts-wrapper {
    height: 100%;
}

.contacts-content {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 0 auto;
    align-content: center;
}

.contacts-center {
    display: flex;
    justify-content: center;
}

.contacts-title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 30px;
    margin-top: 20px;
}

.contacts-subtitle {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
}

</style>